import cache from "memory-cache"
import { GetServerSidePropsContext } from 'next'
import { getSession } from 'next-auth/react'
import { getBrancheTitle } from 'ui/getBrancheTitle'
import { headersCampus, pathify } from './global'
import { Branche, Domaine, Lieu, ModalitesTypes, Module, OrganismeFormation, OrganismeFormationExt, Ref, Refs, Stage, Thematique } from './model'

export const NOT_AUTHORIZED = 'not_authorized'
export const NOT_FOUND = 'not_found'
export const GONE = 'gone'
export const SERVER_ERROR = 'server_error'
export const MAINTENANCE_MODE = 'maintenance_mode'


export interface Page {
    query?: string
    total: number
    data: { id: string, path: string }[]
}


export async function serverFetchAPICampus(context: GetServerSidePropsContext | undefined, url: string): Promise<Response> {
    const session = await getSession(context)
    const params = { headers: headersCampus(session) }
    let res = await fetch(`${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}${url}`, params)
    if (res.status === 429) {
        // Rate limit ? retry
        await new Promise(resolve => setTimeout(resolve, 2000))
        res = await fetch(`${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}${url}`, params)
    }
    if (res.status === 503) {
        // Backend in maintenance mode
        throw new Error(MAINTENANCE_MODE)
    }
    if (res.status === 401) {
        console.log("Session expired, clear session cookie")
        const now = new Date().toUTCString()
        if (process.env.NEXTAUTH_URL!.startsWith('https://'))
            context?.res.setHeader('Set-Cookie', `__Secure-next-auth.session-token=; Max-Agr: 0; Path=/; Expires=${now}; HttpOnly; Secure; SameSite=Lax`)
        else
            context?.res.setHeader('Set-Cookie', `next-auth.session-token=; Max-Age=0; Path=/; Expires=${now}; HttpOnly; SameSite=Lax`)

        console.log("Session expired, retry without auth")
        res = await fetch(`${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}${url}`, { headers: headersCampus() })
        console.log("Res status after logout: " + res.status)
    }
    if (res.status === 404) {
        if (context) {
            context.res.statusCode = 404;
        }
        throw new Error(NOT_FOUND)
    } else if (res.status === 410) {
        if (context) {
            context.res.statusCode = 410;
        }
        throw new Error(GONE)
    } else if (res.status !== 200) {
        throw new Error(SERVER_ERROR)
    }
    return res
}


export async function serverFetchAPIBranches(): Promise<Branche[]> {
    return serverFetchAPIList<Branche>("REF_BRANCHE").then(branches => {
        branches.forEach(branche => branche.titre = getBrancheTitle(branche.titre));

        // Les branches spéciales "À Statut" et "Légal" sont déplacées en fin de liste
        const specialBranches: Branche[] = [];

        const reorderedBranches = branches.filter(branche => {
            if (["À Statut", "Légal"].includes(branche.titre)) {
                specialBranches.push(branche);
                return false;
            }
            return true;
        });

        return reorderedBranches.concat(specialBranches);
    });
}

export async function serverFetchAPIDomaines(): Promise<Domaine[]> {
    return serverFetchAPIList<Domaine>("REF_CAMPUS_DOMAINE")
}

async function serverFetchAPIList<T extends Branche | Domaine>(ref: string): Promise<T[]> {
    // Ne pas utiliser le cache ici, on est au moment du build
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}/refs`,
        { headers: headersCampus() }
    )
    const refs = await res.json()
    const items = refs?.[ref]?.map((item: Ref) => ({
        id: '' + item.IdHeroku,
        idBranche: '' + item.Id,
        path: pathify(item.Libelle__c),
        titre: item.Libelle__c,
    })) ?? []
    items.sort((item1: T, item2: T) => item1.titre.localeCompare(item2.titre))

    return items
}

export async function serverFetchAPIBranche(id: string, includeDomaines: boolean): Promise<Branche> {
    // Ne pas utiliser le cache ici, on est au moment du build
    const res = await fetch(`${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}/refs`, { headers: headersCampus() })
    if (res.status === 404) {
        throw new Error(NOT_FOUND)
    }
    const refs = await res.json()
    const b = refs?.REF_BRANCHE?.find((b: Ref) => '' + b.IdHeroku === id)
    if (!b) {
        throw new Error(NOT_FOUND)
    }
    const domaines = includeDomaines ? refs.REF_CAMPUS_DOMAINE
        .filter((d: any) => d.Branche.includes(`${b.Id}`))
        .map((d: any) => ({
            id: d.IdHeroku,
            path: pathify(d.Libelle__c ?? ''),
            titre: d.Libelle__c,
            branches: d.Branche,
        }))
        .sort((item1: Domaine, item2: Domaine) => item1.titre.localeCompare(item2.titre))
        : undefined

    return {
        id: '' + b.IdHeroku,
        idBranche: '' + b.Id,
        path: pathify(b.Libelle__c),
        titre: b.Libelle__c,
        domaines: domaines,
    }
}


async function serverFetchRefs(): Promise<Refs> {
    const cachedRefs = cache.get('campus/refs')
    if (cachedRefs)
        return cachedRefs
    console.log(`Fetch refs: ${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}/refs`)
    const res = await fetch(`${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}/refs`, { headers: headersCampus() })
    if (res.status === 503) {
        throw new Error(MAINTENANCE_MODE)
    }
    if (res.status === 404) {
        throw new Error(NOT_FOUND)
    }
    const refs = await res.json()
    cache.put('campus/refs', refs, 60 * 60 * 1000)
    return refs
}

export async function serverFetchAPIDomaine(context: GetServerSidePropsContext): Promise<Domaine> {
    const refs = await serverFetchRefs()
    const id = context.params!.domaineId
    const d = refs.REF_CAMPUS_DOMAINE?.find(r => '' + r.IdHeroku === id)
    if (!d) {
        context.res.statusCode = 404;
        throw new Error(NOT_FOUND)
    }

    return {
        id: d.IdHeroku,
        path: pathify(d.Libelle__c ?? ''),
        titre: d.Libelle__c,
        branches: d.Branche,
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function serverFetchAPIModalitesTypes(context: GetServerSidePropsContext): Promise<ModalitesTypes> {
    const refs = await serverFetchRefs()
    return {
        modalites: refs.REF_CAMPUS_MODALITE.map(m => ({ value: m.Id, label: m.Libelle__c })),
        types: refs.REF_CAMPUS_TYPE.map(t => ({ value: t.Id, label: t.Libelle__c })),
    }
}


export async function serverFetchAPIThematiques(context: GetServerSidePropsContext, idBranche: string, page: number, parPage = 100): Promise<Page> {
    const res = await serverFetchAPICampus(context, `/thematiques?id-branche=${idBranche}&par-page=${parPage}&tri=id&page=${page}`)
    const total = res.headers.has('X-Total-Count') ? parseInt(res.headers.get('X-Total-Count') ?? '0') : 0
    const data = await res.json()
    return { 
        total,
        data: data.map((d: any) => ({ id: d.IdHeroku, path: pathify(d.Libelle__c) })),
    }
}


export async function serverFetchAPIThematique(context: GetServerSidePropsContext): Promise<Thematique> {
    const id = context.params!.thematiqueId
    const res = await serverFetchAPICampus(context, `/thematiques/${id}`)
    const t = await res.json()
    return { 
        id: t.IdHeroku,
        path: pathify(t.Libelle__c),
        titre: t.Libelle__c,
        branches: t.Branche__c?.split(';'),
        presentation: t.Presentation__c,
        stages: parseInt(t.NbModules),
        domaine: {
            id: t.Domaine?.IdHeroku ?? '',
            path: pathify(t.Domaine?.Libelle__c ?? ''),
            titre: t.Domaine?.Libelle__c ?? '',
        }
    }
}

export async function serverFetchAPIModules(context: GetServerSidePropsContext, idThematique: string, page: number, parPage = 100): Promise<Page> {
    const res = await serverFetchAPICampus(undefined, `/modules?id-thematique=${idThematique}&page=${page}&par-page=${parPage}`)
    const total = res.headers.has('X-Total-Count') ? parseInt(res.headers.get('X-Total-Count') ?? '0') : 0
    const data = await res.json()
    return {
        query: idThematique,
        total,
        data: data.map((s: any) => ({ id: s.IdHeroku, path: pathify(s.Libelle__c) })),
    }
}

export async function serverFetchAPIModule(context: GetServerSidePropsContext): Promise<Module> {
    const id = context.params!.moduleId
    const res = await serverFetchAPICampus(context, `/modules/${id}`)
    const m = await res.json()
    return { 
        id: m.IdHeroku,
        thematique: {
            id: m.Thematique?.IdHeroku ?? 0,
            path: pathify(m.Thematique?.Libelle__c ?? ''),
            titre: m.Thematique?.Libelle__c ?? '',
            branches: m.Thematique?.Branche__c?.split(';'),
            domaine: {
                id: m.Thematique?.Domaine?.IdHeroku ?? 0,
                path: pathify(m.Thematique?.Domaine?.Libelle__c ?? ''),
                titre: m.Thematique?.Domaine?.Libelle__c ?? '',
            },
        },
        titre: m.Libelle__c,
        path: pathify(m.Libelle__c),
        resume: m.ObjectifModule__c,
        objectifPedagogique: m.ObjectifPedagogique__c,
        publicVise: m.PublicVise__c,
        prerequis: m.PreRequis__c,
        organismes: parseInt(m.NbOFs),
        sessions: parseInt(m.NbSessions),
        nomCertification: m.NomCertification__c ?? null,
        modeCertification: m.ModeCertification__c ?? null,
    } as Module
}

export async function serverFetchAPIModuleOFs(context: GetServerSidePropsContext): Promise<OrganismeFormation[]> {
    const id = context.params!.moduleId
    const res = await serverFetchAPICampus(context, `/modules/${id}/ofs`)
    const json = await res.json()
    // [{"Id":"a089E00008LDVzNQAX","IdHeroku":"6765","Name":"EVOLUTEAM","Stages":"1023,2983"},{"Id":"a089E00008LDVzNQSZ","IdHeroku":"3005","Name":"ORSYS"}]
    return json?.map((of: any) => ({
        id: of.IdHeroku,
        name: of.Name,
        stages: of.Stages?.split(',')?.map((s: string) => parseInt(s)),
    })) ?? []
}

export async function serverFetchAPIModuleLieux(context: GetServerSidePropsContext): Promise<Lieu[]> {
    const id = context.params!.moduleId
    const url = context.params!.ofId 
        ? `/modules/${id}/lieux?id-entreprise=${context.params?.ofId}&page=0&par-page=1000`
        : `/modules/${id}/lieux?page=0&par-page=1000`
    const res = await serverFetchAPICampus(context, url)
    const json = await res.json()
    return json?.map((lieu: any) => ({ 
        ville: lieu.Ville__c,
        region: {
            id: '' + lieu.Departement.Region.IdHeroku,
            idRegion: lieu.Departement.Region.Id,
            label: lieu.Departement.Region.Libelle__c,
        },
    }) as Lieu) ?? []
}



export async function serverFetchAPIStages(context: GetServerSidePropsContext, idThematique: string, page: number, parPage = 100): Promise<Page> {
    const res = await serverFetchAPICampus(undefined, `/stages?id-thematique=${idThematique}&page=${page}&par-page=${parPage}`)
    const total = res.headers.has('X-Total-Count') ? parseInt(res.headers.get('X-Total-Count') ?? '0') : 0
    const data = await res.json()
    return { 
        query: idThematique,
        total,
        data: data.map((s: any) => ({ id: s.IdHeroku, path: pathify(s.Libelle__c) })),
    }
}


export async function serverFetchAPIStage(context: any): Promise<Stage | undefined> {
    /*
    {
        "Id":"a1X9E000003pdfPUAQ",
        "IdHeroku":1369,
        "Libelle__c":"stage Era",
        "ProgrammeDetaille__c":"<p><b>lorem ipsum 2</b></p>",
        "CoutInter__c":1500.5,
        "CoutIntra__c":2600,
        "Type__c":"1",
        "Certification__c":true,
        "CoutCertification__c":240,
        "SupportCours__c":true,
        "CoutSupportCours__c":120,
        "Modalite__c":"4;3",
        "RegionAutorisee__c":"31;26;11",
        "EtablissementOF__c":{
            "Id":"0019E00001Ka5E5QAJ",
            "IdHeroku":398013,
            "Name":"ERA INFORMATIQUE",
            "DescriptionOFCampus__c":null,
            "CertificationsOFCampus__c":null,
            "SIREN__c":"380578542",
            "Email__c":null,
            "BillingStreet":"58 A RUE DU DESSOUS DES BERGES",
            "BillingPostalCode":"75013",
            "BillingCity":"PARIS",
            "SiteInternetOFCampus__c":null
        },
        "Contact":null,
        "Module":{
            "IdHeroku":2,
            "Libelle__c":"module test sch 2",
            "ObjectifModule__c":"<p>TEST 2</p>",
            "ObjectifPedagogique__c":"<p>TEST 2</p>",
            "PublicVise__c":"<p>TEST 2</p>",
            "PreRequis__c":"<p>TEST 2</p>",
            "DureeJours__c":10,
            "DureeHeures__c":50,
            "StagiaireMinIntra__c":5
        }
    }
    */
    const id = context.params.stageId
    const res = await serverFetchAPICampus(context, `/stages/${id}`)
    const s = await res.json()
    return s ? {
        id: s.IdHeroku,
        titre: s.Libelle__c,
        path: pathify(s.Libelle__c),
        programmeDetaille: s.ProgrammeDetaille__c,
        dureeHeures: s.DureeHeures__c,
        coutInter: s.CoutInter__c,
        coutIntra: s.CoutIntra__c,
        type: s.Type__c?.split(';') ?? [],
        certification: s.Certification__c,
        certificationObligatoire: s.CertificationObligatoire__c,
        nomCertification: s.NomCertification__c ?? null,
        coutCertification: s.CoutCertification__c,
        supportCours: s.SupportCours__c, 
        coutSupportCours: s.CoutSupportCours__c, 
        coutintraEtrRevise:  s.CoutintraEtrRevise__c,
        coutinterEtrRevise: s.CoutinterEtrRevise__c,
        coutSupportDeCoursRevise: s.CoutSupportDeCoursRevise__c,
        coutCertificationRevise: s.CoutCertificationRevise__c,
        modalites: s.Modalite__c?.split(';') ?? [],
        modaliteEvaluation: s.ModaliteEvaluation__c ?? null,
        modaliteCertification: s.ModaliteCertification__c ?? null,
        regions: s.RegionAutorisee__c?.split(';') ?? [],
        of: s.EtablissementOF__c && {
            id: s.EtablissementOF__c?.IdHeroku ?? null,
            idEntreprise: s.EntrepriseOF__c?.IdHeroku ?? null,
            name: s.EtablissementOF__c?.Name ?? null,
            path: pathify(s.EtablissementOF__c?.Name),
            ville: s.EtablissementOF__c?.BillingCity ?? null,
            codePostal: s.EtablissementOF__c?.BillingPostalCode ?? null,
            adresse: s.EtablissementOF__c?.BillingStreet ?? null,
        },
        contact: s.Contact && {
            id: s.Contact?.IdHeroku,
            nom: s.Contact?.LastName,
            prenom: s.Contact?.FirstName,
            telephone: s.Contact?.Phone,
            email: s.Contact?.Email,
        },
        module: {
            id: s.Module?.IdHeroku,
            thematique: {
                id: s.Module?.Thematique?.IdHeroku ?? 0,
                path: pathify(s.Module?.Thematique?.Libelle__c ?? ''),
                titre: s.Module?.Thematique?.Libelle__c ?? '',
                branches: s.Module?.Thematique?.Branche__c?.split(';'),
                domaine: {
                    id: s.Module?.Thematique?.Domaine?.IdHeroku ?? 0,
                    path: pathify(s.Module?.Thematique?.Domaine?.Libelle__c ?? ''),
                    titre: s.Module?.Thematique?.Domaine?.Libelle__c ?? '',
                },
            },
            titre: s.Module?.Libelle__c,
            path: pathify(s.Module?.Libelle__c),
            resume: s.Module?.ObjectifModule__c,
            objectifPedagogique: s.Module?.ObjectifPedagogique__c,
            publicVise: s.Module?.PublicVise__c,
            prerequis: s.Module?.PreRequis__c,
            dureeHeures: s.Module?.DureeHeures__c,
            minStagiairesIntra: s.Module?.StagiaireMinIntra__c ?? null,
        },
    } : undefined
}

export async function serverFetchAPIOF(context: GetServerSidePropsContext): Promise<OrganismeFormationExt | undefined> {
    /*
    {
        "Id":"0019E00001KYtnlQAD",
        "IdHeroku":"4566",
        "Name":"Evoluteam",
        "DescriptionOFCampus__c":"Plus de 44 ans d’expérience ont permis à ORSYS d’être considéré comme le leader de la formation aux technologies numériques, au management, au développement personnel et aux métiers de l’entreprise. Son approche multispécialiste lui assure une position de choix pour accompagner les entreprises dans l’amélioration de leurs performances et leur transformation digitale",
        "CertificationsOFCampus__c":"ISO 9001;Qualibat",
        "SIREN__c":"662042449",
        "Email__c":"test@test.com",
        "BillingStreet":"16 BD DES ITALIENS",
        "BillingPostalCode":"75009",
        "BillingCity":"PARIS",
        "SiteInternetOFCampus__c":"https://www.evoluteam.fr"
    }
    */
    const id = context.params!.organismeId
    const res = await serverFetchAPICampus(context, `/entreprises/${id}`)
    const s = await res.json()

    const split = (s?: string) => {
        return s?.replace(/\\,/g, '\x00').split(',').map(t => t.replaceAll('\x00', ',')) ?? []
    }

    return s ? {
        id: s.IdHeroku,
        idEntreprise: null,
        name: s.Name,
        path: pathify(s.Name),
        description: s.DescriptionOFCampus__c,
        certifications: split(s.CertificationsOFCampus__c),
        siren: s.SIREN__c ?? null,
        email: s.Email__c,
        adresse: s.BillingStreet,
        codePostal: s.BillingPostalCode,
        ville: s.BillingCity,
        siteWeb: s.SiteInternetOFCampus__c,
    } : undefined
}
