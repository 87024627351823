import { SessionProvider, signIn, signOut, useSession } from 'next-auth/react'
import { AppProps } from 'next/dist/shared/lib/router/router'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { Entreprise } from 'services/model'
import { MAINTENANCE_MODE } from 'services/server'
import { SWRConfig, SWRConfiguration } from 'swr'
import 'atlas-ds/atlas/atlas.css'
import 'atlas-ds/campus/campus.css'
import * as ga from '../lib/ga'

const BOT_UA = [
    '\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/',
    'googlebot',
    'baiduspider',
    'gurujibot',
    'yandexbot',
    'slurp',
    'msnbot',
    'bingbot',
    'facebookexternalhit',
    'linkedinbot',
    'twitterbot',
    'slackbot',
    'telegrambot',
    'applebot',
    'pingdom',
    'tumblr',
    'taiko',
]

function Auth({ children }: { children: JSX.Element }) {
    const { data: session, status: authenticating } = useSession()
    const isUser = !!session?.user
    const router = useRouter()
    
    useEffect(() => {
        if (!router.isReady)
            return
        
        const idx = window.location.href.indexOf('/', 'https://'.length)
        const baseLocation = idx > 0 ? window.location.href.substring(0, idx) : window.location.href
        // TODO: trouver un meilleur moyen de détecter qu'on doit revalider le statut d'authentification
        let tried = document.referrer && document.referrer.startsWith(baseLocation)
        if (router.query.error) {
            // Supprime le message d'erreur de l'url
            tried = true
            router.replace((router.query as any).callbackUrl ?? '/', undefined, { shallow: true })
            // Force la suppression des infos d'authentification locales (expiration ou federated logout depuis un autre site)
            signOut({ redirect: false })
        }
        if (router.asPath === '/indisponible') {
            tried = true
        }
        if (process.env.NEXT_PUBLIC_SEL_BASE_URL && document.referrer.startsWith(process.env.NEXT_PUBLIC_SEL_BASE_URL)) {
            tried = false
        }
        if (sessionStorage.getItem('loggingOut') === 'true') {
            tried = true
        }
        if (!tried && window.navigator && window.navigator.userAgent) {
            // Pas de redirection d'authentification pour les bots de recherche
            if (new RegExp(`(${BOT_UA.join('|')})`, 'ig').test(window.navigator.userAgent.toLowerCase()))
                tried = true
        }
        
        if ((authenticating === 'unauthenticated' || authenticating === 'authenticated') && !tried) {
            sessionStorage.removeItem('loggingOut')
            console.log('signIn init: isUser=' + isUser + ', authing=' + authenticating + ', tried=' + tried + ', referrer=' + document.referrer)
            signIn('atlas', { callbackUrl: window.location.href }, { prompt: 'none' }) // If not authenticated, force log in
                .then(res => {
                    console.log("signIn result: " + JSON.stringify(res))
                    tried = true
                })
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady, isUser, authenticating])
  
    return children
}

export const EntrepriseContext = React.createContext<any[]>([null, null])

export function useEntreprise() {
    return useContext(EntrepriseContext)[0] as Entreprise | null
}

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
    const [entreprise, setEntreprise] = useState<Entreprise | null>(null)
    const router = useRouter()
    const canonicalUrl = (process.env.NEXT_PUBLIC_BASE_URL + (router.asPath === "/" ? "": router.asPath)).split("?")[0]

    const swrConfig = {
        onError: (err: any) => {
            if (err.message === MAINTENANCE_MODE) {
                router.push('/indisponible')
            } else {
                console.log(err)
            }
        },
    } as SWRConfiguration
    
    useEffect(() => {
      const handleRouteChange = (url:any) => {
        ga.pageViewMatomo(url)
      }
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)
  
      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, [router.events])

    // Bypass typing error
    const RootComponent = Component as any

    return (
        <SWRConfig value={ swrConfig } >
            <EntrepriseContext.Provider value={ [entreprise, setEntreprise] }>
                <Head>
                    <title key="title">campusAtlas</title>
                    <link rel="canonical" href={canonicalUrl} />
                    <meta property="og:image" content={`${process.env.NEXTAUTH_URL}/logo.png`} key="og:image" />
                    <link rel="icon" type="image/svg+xml" href={`/favicon${process.env.NEXT_PUBLIC_ENV ? `_${process.env.NEXT_PUBLIC_ENV}` : ''}.svg`} />
                </Head>
                <SessionProvider session={ session }>
                    <Auth>
                        <RootComponent { ...pageProps } />
                    </Auth>
                </SessionProvider>
            </EntrepriseContext.Provider>
        </SWRConfig>
    )
}
